import * as React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Contact = () => {
  const [formData, updateFormData] = React.useState({});
  const formRef = React.useRef(null);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => updateFormData({ ...formData, [e.target.name]: e.target.value.trim() });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      await fetch(formRef.current.action, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          ...formData,
          'form-name': formRef.current.getAttribute('name'),
        }).toString(),
      });
      window.gtag('event', 'form_submit', {
        message: 'Contact Form Submission Successful',
      });
    } catch (e) {
      window.gtag('event', 'form_submit', {
        message: 'Contact Form Submission Unsuccessful',
        error: e,
      });
    }
  };

  return (
    <Layout>
      <SEO title="Contact me" pathname="/contact" />
      <div className="text-center mb-6">
        <h1>Contact me</h1>
        <p>
          Fill out the form below to start a conversation with the Nieuwenhuis
          Web Development Team
        </p>
      </div>
      <form
        ref={formRef}
        method="post"
        action="/thank-you"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="contact"
        onSubmit={handleSubmit}
      >
        <div className="bg-gray-300 p-6 rounded">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />
          <div className="flex flex-col mb-4">
            <label htmlFor="name" className="mb-2 font-bold text-lg">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              placeholder="J. Doe"
              className="border py-2 px-3"
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label htmlFor="email" className="mb-2 font-bold text-lg">
              Email address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="j.doe@gmail.com"
              className="border py-2 px-3"
              onChange={handleChange}
              required
            />
          </div>
          <div className="flex flex-col mb-4">
            <label htmlFor="message" className="mb-2 font-bold text-lg">
              Message
            </label>
            <textarea
              name="message"
              id="message"
              placeholder="Hi Nieuwenhuis Web Development Team, I want to share something."
              className="border py-2 px-3"
              rows={5}
              onChange={handleChange}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Send
          </button>
        </div>
      </form>
    </Layout>
  );
};

export default Contact;
